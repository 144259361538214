exports.components = {
  "component---src-layouts-jasons-deli-app-donation-[id]-js": () => import("./../../../src/layouts/jasonsDeli/app/donation/[id].js" /* webpackChunkName: "component---src-layouts-jasons-deli-app-donation-[id]-js" */),
  "component---src-layouts-jasons-deli-app-donation-request-js": () => import("./../../../src/layouts/jasonsDeli/app/donation-request.js" /* webpackChunkName: "component---src-layouts-jasons-deli-app-donation-request-js" */),
  "component---src-layouts-jasons-deli-app-donation-requests-js": () => import("./../../../src/layouts/jasonsDeli/app/donation-requests.js" /* webpackChunkName: "component---src-layouts-jasons-deli-app-donation-requests-js" */),
  "component---src-layouts-jasons-deli-app-event-[id]-js": () => import("./../../../src/layouts/jasonsDeli/app/event/[id].js" /* webpackChunkName: "component---src-layouts-jasons-deli-app-event-[id]-js" */),
  "component---src-layouts-jasons-deli-app-index-js": () => import("./../../../src/layouts/jasonsDeli/app/index.js" /* webpackChunkName: "component---src-layouts-jasons-deli-app-index-js" */),
  "component---src-layouts-jasons-deli-app-inkind-[id]-js": () => import("./../../../src/layouts/jasonsDeli/app/inkind/[id].js" /* webpackChunkName: "component---src-layouts-jasons-deli-app-inkind-[id]-js" */),
  "component---src-layouts-jasons-deli-app-invite-[id]-js": () => import("./../../../src/layouts/jasonsDeli/app/invite/[id].js" /* webpackChunkName: "component---src-layouts-jasons-deli-app-invite-[id]-js" */),
  "component---src-layouts-jasons-deli-app-login-js": () => import("./../../../src/layouts/jasonsDeli/app/login.js" /* webpackChunkName: "component---src-layouts-jasons-deli-app-login-js" */),
  "component---src-layouts-jasons-deli-app-new-request-js": () => import("./../../../src/layouts/jasonsDeli/app/new-request.js" /* webpackChunkName: "component---src-layouts-jasons-deli-app-new-request-js" */),
  "component---src-layouts-jasons-deli-app-organization-details-[id]-js": () => import("./../../../src/layouts/jasonsDeli/app/organization-details/[id].js" /* webpackChunkName: "component---src-layouts-jasons-deli-app-organization-details-[id]-js" */),
  "component---src-layouts-jasons-deli-app-organizations-js": () => import("./../../../src/layouts/jasonsDeli/app/organizations.js" /* webpackChunkName: "component---src-layouts-jasons-deli-app-organizations-js" */),
  "component---src-layouts-jasons-deli-app-past-events-js": () => import("./../../../src/layouts/jasonsDeli/app/past-events.js" /* webpackChunkName: "component---src-layouts-jasons-deli-app-past-events-js" */),
  "component---src-layouts-jasons-deli-app-personal-details-js": () => import("./../../../src/layouts/jasonsDeli/app/personal-details.js" /* webpackChunkName: "component---src-layouts-jasons-deli-app-personal-details-js" */),
  "component---src-layouts-jasons-deli-app-reservation-[id]-js": () => import("./../../../src/layouts/jasonsDeli/app/reservation/[id].js" /* webpackChunkName: "component---src-layouts-jasons-deli-app-reservation-[id]-js" */),
  "component---src-layouts-jasons-deli-app-rinvite-[id]-js": () => import("./../../../src/layouts/jasonsDeli/app/rinvite/[id].js" /* webpackChunkName: "component---src-layouts-jasons-deli-app-rinvite-[id]-js" */),
  "component---src-layouts-jasons-deli-app-share-[id]-js": () => import("./../../../src/layouts/jasonsDeli/app/share/[id].js" /* webpackChunkName: "component---src-layouts-jasons-deli-app-share-[id]-js" */),
  "component---src-layouts-jasons-deli-app-upcoming-events-js": () => import("./../../../src/layouts/jasonsDeli/app/upcoming-events.js" /* webpackChunkName: "component---src-layouts-jasons-deli-app-upcoming-events-js" */),
  "component---src-layouts-jasons-deli-app-user-management-js": () => import("./../../../src/layouts/jasonsDeli/app/user-management.js" /* webpackChunkName: "component---src-layouts-jasons-deli-app-user-management-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */)
}

